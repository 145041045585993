<template>
  <div>
    <div>
      <img class="top_image" :src="getImg(menu.bannerImg)" alt=""/>
    </div>
    <!-- 面包屑 -->
    <div class="mbx">
      <div style="width: 1200px; margin: 20px auto">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 16px">
            <router-link to="/">首页</router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 16px">{{ menu.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <p class="small_line"></p>
    </div>
    <div class="fgx">
      <p class="title"><span>{{ menu.name }}</span></p>
      <ul style="font-size: 16px">
        <a v-for="(item,index) in subMenu" :href="'#' + maodian + '#'+index" :key="index">{{ item.name }}</a>
      </ul>
      <div class="line">
        <p class="linebg"></p>
      </div>
    </div>
    <div style="background: url('../../static/切图文件/bg-map-1.png') no-repeat top center;">
      <!-- 网络货运平台 -->
      <div class="middle" v-for="(item,index) in subMenu" :key="index" :id="maodian+'#'+index">
        <p class="middle_title">
          <img class="imageww" src="../../static/切图文件/ICON-left.png" alt=""/>
          <span class="name">{{ item.name }}</span>
          <img class="imageww" src="../../static/切图文件/ICON-right.png" alt=""/>
        </p>
        <div class="title_content">
          <div class="title_content_del">
            <p class="ql-editor contentdel" v-html="item.content" style="text-algin: left"></p>
          </div>
        </div>
        <!-- 分割线 -->
        <div class="bigline"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMenuList,
  getContentList
} from "../request/index";

export default {
  data() {
    return {
      maodian: "",
      menu: {},
      subMenu: []
    };
  },
  created() {
    getMenuList({path: this.$route.path}).then(res => {
      this.menu = res.data[0];
      getMenuList({parentId: this.menu.id}).then(res => {
        this.subMenu = res.data;
        for (let i = 0; i < this.subMenu.length; i++) {
          getContentList({menuId: this.subMenu[i].id}).then(res1 => {
            if(res1.rows.length>0){
              let content = res1.rows[0].content;
              this.$set(this.subMenu[i], 'content', content)
            }
          });
        }
      });
    });
    this.maodian = window.location.hash.slice(2);
  },
  mounted() {
  },
  methods: {
    getImg(url) {
      if (url) {
        return window.apiUrl + url;
      } else {
        return "../../static/banner-default.png";
      }
    }
  },
  computed: {}
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-align: center;
}

.top_image {
  width: 100%;
}

.small_line {
  background: #ddd;
  height: 1px;
}

.fgx {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: left;
  /* white-space: nowrap; */
}

.fgx .title {
  font-weight: 900;

  width: 100px;
  font-size: 20px;
  margin-bottom: 10px;
  display: inline-block;
}

.fgx ul {
  /* display: inline-block; */
  float: right;
}

.fgx ul a {
  float: left;
  margin-left: 20px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.fgx .line {
  width: 100%;
  height: 5px;
  background-color: #efefef;
}

.line .linebg {
  height: 5px;
  width: 80px;
  background-color: black;
}

.middle .middle_title {
  margin-top: 50px;
  margin-bottom: 10px;
}

.imageww {
  display: inline-block;
  vertical-align: text-bottom;
}

.middle .middle_title {
  font-size: 30px;
  font-weight: 700;
  color: rgb(249, 145, 0);
}

.middle .title_content {
  margin-top: 30px;
  width: 1000px;
  margin: 0 auto;
}

.middle .title_content .contentdel {
  text-align: justify;
  font-weight: 500;
  font-size: 20px;
  /* margin-top: 30px; */
}

.middle .title_content ul {
  margin: 0;
  padding: 0;
}

.middle .title_content .prolist {
  width: 1000px;
  /* margin: 10px auto; */
  text-align: left;
  margin-left: 0;
}

.middle .title_content .prolist li {
  list-style: none;
  display: inline-block;
  width: 25%;
}

.middle .title_content .prolist li .one {
  color: rgb(249, 145, 0);
}

.middle .content_image {
  width: 1000px;
  margin: 30px auto;
}

.middle .content_image img {
  width: 423px;
}

.bigline {
  height: 1px;
  width: 1200px;
  margin: 10px auto;
  background-color: #dddddd;
}

.middle .title_content .content_line {
  height: 1px;
  width: 1000px;
  background-color: #dddddd;
  margin: 10px auto;
}

.middle .title_content .downcontent {
  width: 1000px;
  margin: 10px auto;
  text-align: left;
  padding-bottom: 50px;
}

.middle .upimage {
  width: 1000px;
  margin: 0 auto;
  text-align: justify;
}

.middle .upimage img {
  height: 265px;
}

.middle .title_content .title_content_del {
  /* margin-top: 40px; */
  text-align: left;
  padding-bottom: 30px;
}
</style>